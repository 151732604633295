export const parseSigner = data => {
  const subjectAccounts = data.subjectAccounts.split(',');
  const subjectNames = data.subjectNames.split(',');
  const subjectTypes = data.subjectTypes.split(',');
  const signerAccounts = data.signerAccounts.split(',');
  const signerNames = data.signerNames.split(',');
  const signerResults = data.signerResults.split(',');
  const signerStates = (
    data.signerStates ||
    Array.from(subjectAccounts)
      .map(() => '0')
      .join(',')
  ).split(',');
  const len = subjectAccounts.length;
  if (
    subjectNames.length != len ||
    subjectTypes.length != len ||
    signerAccounts.length != len ||
    signerNames.length != len
  ) {
    console.log('签署人配置有误');
  } else {
    return subjectAccounts.map((it, index) => {
      return {
        subjectAccount: it,
        subjectName: subjectNames[index],
        subjectType: subjectTypes[index],
        signerAccount: signerAccounts[index],
        signerName: signerNames[index],
        signerState: signerStates[index],
        signerResult: signerResults[index]
      };
    });
  }
};

<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/contract.png" alt />
        <span class="center">合同管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">合同列表</span>
      </div>
      <div>
        <el-button v-access data-ctrl="contractAdd" @click="addObject" size="small" icon="el-icon-plus" type="primary">新
          建</el-button>
        <el-button @click="batchSigning" size="small" icon="el-icon-plus" type="primary">批量签署</el-button>
      </div>
    </div>

    <el-card class="box-card">
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" style="float: left">
          <span class="seach_text">关键字：</span>

          <el-input size="mini" placeholder="请输入合同名称" v-model="formInline.keywords" @input="keywords()"
            style="margin-right: 10px; width: 250px" prefix-icon="el-icon-search" clearable></el-input>
        </el-col>
        <el-col :span="6">
          <span class="seach_text">签署状态：</span>

          <el-select size="mini" v-model="formInline.state" placeholder="请选择签署状态" clearable @change="loadData('seath')">
            <el-option :label="item.label" :value="item.value" v-for="(item, i) in contractStateOptions" :key="i">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <span class="seach_text">合同分类：</span>
          <el-select size="mini" v-model="formInline.type" placeholder="请选择合同分类" clearable @change="loadData('seath')">
            <el-option label="全部" value></el-option>
            <el-option label="劳动合同" value="1"></el-option>
            <el-option label="其他" value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <span class="seach_text">签署截止日期：</span>
          <el-date-picker size="mini" v-model="formInline.maxSignDeadline" type="date" placeholder="请选择合同签署截止日期"
            value-format="yyyy-MM-dd HH:mm:ss" @input="loadData('seath')">
          </el-date-picker>
        </el-col>
      </el-row>

      <el-table :data="list" style="width: 100%">
        <el-table-column label="合同名称" width="250">
          <template slot-scope="scope">
            <div class="clickColor" @click="goDetail({ code: scope.row.code })">{{ scope.row.name }}</div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="签署流程id">
        <template slot-scope="scope">
          {{ scope.row.flowId }}
        </template>
        </el-table-column>-->
        <!-- <el-table-column label="签署人账号">
        <template slot-scope="scope">
          {{ scope.row.signerAccounts }}
        </template>
        </el-table-column>-->
        <el-table-column label="签署主体" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.signers" :key="index">
              <span>{{ index | subjectTypeVal }}：</span>
              <span>{{ item.subjectName || "未设置" }}</span>
            </div>
            <!-- <span
            v-if="scope.row.subjectNames.length === 0"
            class="font-color-secondary"
            >签署信息未设置</span
            >-->
          </template>
        </el-table-column>
        <el-table-column label="签署人">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.signers" :key="index">
              <span>{{ item.signerName || "未设置" }}</span>
              <!-- <el-tooltip
              v-if="item.signerName"
              class="item"
              effect="dark"
              :content="item.signerResult"
              placement="top-start"
              >-->
              <i class="iconfont" :class="{
                iconclock: item.signerState === '0',
                iconsuccess: item.signerState === '2',
                iconreeor: item.signerState === '4',
              }"></i>
              <!-- </el-tooltip> -->
              <!-- <span style="font-size: 12px">{{ item.signerResult }}</span> -->
            </div>
            <!-- <span
            v-if="scope.row.signerNames.length === 0"
            class="font-color-secondary"
            >签署信息未设置</span
            >-->
          </template>
        </el-table-column>
        <el-table-column label="合同分类">
          <template slot-scope="scope">{{ scope.row.type | contractTypeVal }}</template>
        </el-table-column>
        <el-table-column label="签署状态">
          <template slot-scope="scope">
            <el-tag v-if="
              scope.row.state === '0' ||
              scope.row.state === '1' ||
              scope.row.state === '6' ||
              !scope.row.state
            " type="info">{{ scope.row.state | contractStateVal }}</el-tag>
            <el-tag v-if="scope.row.state === '2' || scope.row.state === '3'" type="primary">{{
                scope.row.state |
                contractStateVal
            }}</el-tag>
            <el-tag v-if="scope.row.state === '4'" type="success">
              {{
                  scope.row.state | contractStateVal
              }}
            </el-tag>
            <el-tag v-if="scope.row.state === '5' || scope.row.state === '7'" type="danger">{{
                scope.row.state |
                contractStateVal
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="签署日期">
          <template slot-scope="scope">{{ scope.row.signDeadline | dateVal }}</template>
        </el-table-column>

        <el-table-column label="创建人">
          <template slot-scope="scope">{{ scope.row.creatorName || "系统" }}</template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="shuxian" v-access data-ctrl="contractEdit" v-show="scope.row.state === '1' || !scope.row.state">
              <el-button type="text" @click="goEdit({ code: scope.row.code })">{{
                  scope.row.signerAccounts ? "编辑" :
                    "去设置"
              }}</el-button>
              <el-divider direction="vertical"></el-divider>
            </div>
            <div class="shuxian" v-access data-ctrl="contractFlowStart" v-show="
              scope.row.signerAccounts &&
              (scope.row.state === '1' || !scope.row.state)
            ">
              <el-button @click="startFlow(scope.row)" :loading="scope.row.startContractLoading" type="text">发起
              </el-button>
              <el-divider direction="vertical"></el-divider>
            </div>
            <div class="shuxian" v-if="scope.row.isSign">

              <el-button @click="getSignUrl(scope.row)" :loading="scope.row.getSignUrlLoading" type="text">签署
              </el-button>

              <el-divider direction="vertical"></el-divider>
            </div>
            <div class="shuxian" v-access data-ctrl="contractDel" v-if="
              scope.row.state === '1' ||
              scope.row.state === '2' ||
              scope.row.state === '3'
            ">
              <el-popover placement="top" width="400" trigger="manual" :ref="'popover-' + scope.row.id">
                <div class="del-pop">
                  <img src="../../assets/del.png" alt />
                </div>
                <div class="del-title">删除合同</div>
                <div class="del-sumtitle">是否确认删除本条合同记录？</div>
                <div class="del-btn">
                  <el-button type="primary" @click="del(scope.row)" plain>确认</el-button>
                  <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
                </div>
                <el-button type="text" slot="reference" @click="pOpen(scope.row.id)">删除</el-button>
                <el-divider direction="vertical"></el-divider>
              </el-popover>
            </div>
            <!-- <div class="shuxian" v-access data-ctrl="contractComplete"
              v-show="scope.row.filePaths && scope.row.state === '1'">
              <el-divider direction="vertical"></el-divider>
              <el-button @click="complete(scope.row)" type="text">签署完成</el-button>
            </div> -->

            <el-button v-access data-ctrl="contractCancel" v-show="scope.row.state === '4'" @click="cancel(scope.row)"
              type="text">合同作废</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination background :current-page.sync="pageNumber" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :page-size="pageSize" prev-text="上一页" next-text="下一页"
        layout="prev,pager, next,jumper" :total="total"></el-pagination>
    </div>

    <addDialog :dialogVisible.sync="addDialogVisible" @close="close()"></addDialog>

    <el-dialog title="批量签署合同" :visible.sync="batchSigningShow" :before-close="handleClose">
      <el-date-picker v-model="value1" type="daterange" format="yyyy-MM-dd" :unlink-panels="true" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" @change="getList"
        :picker-options="pickerOptions">
      </el-date-picker>
      <el-table style="margin-top: 10px" :data="gridData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column property="signFlowTitle" label="合同名称"></el-table-column>
        <el-table-column label="甲方">
          <template slot-scope="scope">{{
              scope.row.signerInfos.find(it => it.operatorName != it.signerName).signerName
          }}({{ scope.row.signerInfos.find(it => it.operatorName != it.signerName).operatorName }})</template>
        </el-table-column>
        <el-table-column label="乙方">
          <template slot-scope="scope">{{
              scope.row.signerInfos.find(it => it.operatorName === it.signerName).signerName
          }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="dispathShown" @click="batchSigningBtn()">签 署</el-button>
        <el-button @click="batchSigningShow = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
// import * as _ from 'lodash';
//接口：
import { api } from '/src/api/base';
import { parseSigner } from './utils';
import { generatePath } from '../../api/base/index';
//新增接口
export const ContractAdd = api('/contract')('contract.add.json');
//获取签署列表
export const contractBatchSignList = api()('contract.batchSignList.json');
export const contractbatchSignUrl = api()('contract.batchSignUrl.json');
export const contractPersionAuth = api()('contract.persionAuth.json');
export const contractOrgAuth = api()('contract.orgAuth.json');

//删除接口
export const ContractDel = api()('contract.del.json');
//合同作废
export const ContractCancel = api()('contract.cancel.json');
//合同签署完成
export const ContractComplete = api()('contract.complete.json');
//查询列表接口
export const ContractList = api('/contract')('contract.list.json');
//查询详情接口
export const ContractDetail = api('/contract')('contract.describe.json');
//编辑接口
export const ContractEdit = api('/contract')('contract.edit.json');
export const ContractFlowStart = api('/contract')('contract.flow.start.json');
export const ContractSignUrlDescribe = api('/contract')(
  'contract.signer.url.json',
);
export default {
  components: {
    addDialog: () => import('./Add'),
  },
  data () {
    return {
      getSignUrlLoading: false,
      startContractLoading: false,
      batchSigningShow: false,
      value1: [moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      gridData: [],
      selectList: [],
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',
        state: '',
        type: '',
        maxSignDeadline: '',
        minSignDeadline: '',
      },
      list: [],
      addDialogVisible: false,
      dispathShown: true,
      isEnterpriseAdmin: null,
      dialogData: null,
      contractStateOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '草稿',
          value: '1',
        },
        // {
        //   label: "发起中",
        //   value: "2"
        // },
        {
          label: '签署中',
          value: '3',
        },
        {
          label: '签署成功',
          value: '4',
        },
        {
          label: '签署失败',
          value: '5',
        },
        {
          label: '签署过期',
          value: '6',
        },
        {
          label: '已作废',
          value: '7',
        },
      ],
      pickerOptions: {
        disabledDate (time) {
          return !(time.getTime() < Date.now() && (Date.now() - 90 * 24 * 60 * 60 * 1000) < time.getTime())
        }
      }
    };
  },

  created () {
    // const queryParams = this.$route.query;
    // if (queryParams.state) {
    //   this.formInline.state = queryParams.state;
    //   console.log(this.formInline.state);
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '1') {
    //   this.formInline.minSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    //   this.formInline.maxSignDeadline = moment()
    //     .endOf('day')
    //     .format('YYYY-MM-DD HH:mm:ss');
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '2') {
    //   this.formInline.minSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    //   this.formInline.maxSignDeadline = moment(
    //     moment()
    //       .endOf('day')
    //       .valueOf() +
    //     7 * 24 * 60 * 60 * 1000,
    //   ).format('YYYY-MM-DD HH:mm:ss');
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '3') {
    //   this.formInline.minSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    //   this.formInline.maxSignDeadline = moment([
    //     moment().year(),
    //     moment().month() + 1,
    //     moment().date(),
    //   ])
    //     .endOf('day')
    //     .format('YYYY-MM-DD HH:mm:ss');
    // }
    // if (queryParams.deadTime && queryParams.deadTime === '4') {
    //   this.formInline.maxSignDeadline = moment().format('YYYY-MM-DD HH:mm:ss');
    // }
    this.isEnterpriseAdmin = window.localStorage.getItem('isEnterpriseAdmin')

    console.log(typeof (this.isEnterpriseAdmin));
    this.loadData();

  },
  methods: {
    async batchSigning () {
      this.batchSigningShow = true
      await this.getList()
    },
    getList () {
      console.log(generatePath('web/system/contract/list'));
      let dateNum = moment(this.value1[1]).diff(moment(this.value1[0]), 'days')
      if (dateNum > 89) {
        this.$message.error('查询时间区间最大三个月');
      }
      else {
        const params = {
          startTime: this.value1[0],
          endTime: this.value1[1],
          // redirectUrl: generatePath('web/system/contract/list')
          redirectUrl: window.location.href
        };
        contractBatchSignList(params).then((res) => {
          if (res.authUrl) {
            window.location.href = res.authUrl
          }
          else {
            this.gridData = res

            console.log(this.gridData, res);
          }

        })
      }

    },

    batchSigningBtn () {
      if (this.selectList.length > 0) {

        const params = {
          flowIds: this.selectList,
          redirectUrl: window.location.href
        };
        contractbatchSignUrl(params).then((res) => {

          if (res.message.includes('获取批量签署链接失败: 签署人')) {
            this.$confirm('签署人未授权免登录授权, 是否授权?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {

              const params = {
                redirectUrl: window.location.href
              };
              contractPersionAuth(params).then((res) => {
                window.location.href = res.data
              })

            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消授权'
              });
            });
          }
          else if (res.message.includes('获取批量签署链接失败: 签署主体')) {
            this.$confirm('企业未授权免登录授权, 是否授权?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              const params = {
                redirectUrl: window.location.href
              };
              contractOrgAuth(params).then((res) => {
                window.location.href = res.data
              })

            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消授权'
              });
            });
          }
          else if (res.message === '成功') {
            window.open(res.data.batchSignShortUrlWithoutLogin, '_blank')
          }
        })

      }
      else {
        this.$message({
          type: 'info',
          message: '请选择所要签署合同'
        });
      }
    },
    handleSelectionChange (val) {
      this.selectList = val.map((it) => it.signFlowId);
      console.log(val);
      this.dispathShown = !this.selectList.length
    },
    handleClose () {
      this.batchSigningShow = false
    },
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {
      // console.log(this.$refs["popover-" + id]);
      this.$refs['popover-' + id].doClose();
    },
    keywords () {
      this.pageNumber = 1;
      this.loadData();
    },
    async getSignUrl (row) {
      row.getSignUrlLoading = true;
      const url = await ContractSignUrlDescribe({
        contractCode: row.code,
        signerAccount: window.localStorage.getItem('code'),
      });
      row.getSignUrlLoading = false;
      console.log(url, '_blank');
      window.open(url, '_blank')
    },
    // 删除合同
    async del (row) {
      await ContractDel({
        code: row.code,
      });
      this.$message.success(`合同${row.name}删除成功！`);
      this.doCancel(row.id);
      this.formInline.state = '';
      this.loadData();
    },
    // 合同作废
    async cancel (row) {
      await ContractCancel({
        code: row.code,
      });
      this.$message.success(`合同${row.name}已作废！`);
      this.formInline.state = '';
      this.loadData();
    },
    // 签署完成
    async complete (row) {
      await ContractComplete({
        code: row.code,
      });
      this.$message.success(`合同${row.name}已签署完成！`);
      this.formInline.state = '';
      this.loadData();
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }

      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        $ignoreRepeat: true,
      };
      ContractList(params).then((res) => {
        this.list = res.list.map((it) => {
          const userIndex = it.signerAccounts.split(',').indexOf(window.localStorage.getItem('code'))
          return {
            ...it,
            getSignUrlLoading: false,
            startContractLoading: false,
            signers: parseSigner(it),
            isSign: userIndex > -1 && ['1', '0'].includes(it.signerStates.split(',')[userIndex]) && !(it.signerStates.split(',').includes('3') || it.signerStates.split(',').includes('4')),

            // ["1", "3"].includes(it.state) || isEnterpriseAdmin === 'false'
            // <el-button
            //     v-if="it.state === '3' && scope.row.signerResults.split(',')[0] === '签署完成' && isEnterpriseAdmin === 'false'"
            //     @click="getSignUrl(scope.row)" :loading="scope.row.getSignUrlLoading" type="text">签署1
            //   </el-button>
            //   <el-button
            //     v-if="scope.row.signerResults.split(',')[0] != '签署完成' && isEnterpriseAdmin === 'true' && scope.row.state === '1'"
            //     @click="getSignUrl(scope.row)" :loading="scope.row.getSignUrlLoading" type="text">签署
            //   </el-button>
            // _.intersection(it.signerAccounts.split(','), [
            //   window.localStorage.getItem('code'),
            // ]).length > 0,

          };
        });
        this.total = res.total;
      });
    },
    onSubmit () {
      console.log('submit!');
    },
    goBack () {
      window.history.go(-1);
    },
    addObject () {
      this.addDialogVisible = true;
    },
    goEdit (obj) {
      this.$router.push({
        path: '/system/contract/add',
        query: obj,
      });
    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/contract/detail',
        query: obj,
      });
    },
    close () {
      this.loadData();
    },
    async startFlow (it) {
      it.startContractLoading = true;
      await ContractFlowStart({ code: it.code });
      this.formInline.state = '';
      this.loadData();
      it.startContractLoading = false;
      this.$message.success('发起成功');
    },
    putAway () {
      this.formInline.state = '';
      this.formInline.type = '';
      this.pageNumber = 1;
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.iconsuccess {
  color: #67c23a;
}

.iconreeor {
  color: #f56c6c;
}

.del-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.del-title {
  font-size: 17px;
  text-align: center;
  color: #333333;
  margin: 15px 0;
}

.del-sumtitle {
  font-size: 13px;
  text-align: center;
  color: #a9a9a9;
  margin: 0 0 5px 0;
}

.table_border {
  margin: 20px 0;

  .table_top {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    background: #4977fc;
    color: #fff;
    font-size: 14px;
  }

  .table_demo {
    padding: 35px 0;
    font-size: 10px;
  }
}
</style>
